/**
 * Version: 1.0
 * Author: Kádár Péter <kadar.peter@gmail.com>
 * Project: royalsutode.hu
 */

jQuery(document).ready(function ($) {
  $('.js-slider').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false
  });
});