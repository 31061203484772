document.addEventListener('DOMContentLoaded', initNavigation);

function initNavigation() {
    let $overlay = document.createElement('div'),
        $body = document.querySelector('body'),
        $navbar = document.querySelector('.js-navbar'),
        $navbarToggle = document.querySelector('.js-navbar-toggle');

    $overlay.classList.add('overlay');
    $body.appendChild($overlay);

    $overlay.addEventListener('click', function (e) {
        e.preventDefault();
        closeNav();
    });

    $navbarToggle.addEventListener('click', function (e) {
        e.preventDefault();

        if ($body.classList.contains('nav-is-visible')) {
            closeNav();
        } else {
            openNav();
        }
    });

    function openNav() {
        $body.classList.add('nav-is-visible');
        $overlay.classList.add('is-visible');
        $navbar.classList.add('is-open');
        $navbarToggle.classList.add('is-active');
    }

    function closeNav() {
        $body.classList.remove('nav-is-visible');
        $overlay.classList.remove('is-visible');
        $navbar.classList.remove('is-open');
        $navbarToggle.classList.remove('is-active');
    }
}